import {
  getPageMetadata,
  getCampaignTraits,
  getProfileData,
  getAuthData,
  getUUID,
  getCurrentCmpParameters,
  getCookie,
  getFavoritesLists,
  getXID
} from './utils';

class SegmentAnalytics {
  constructor () {
    this.metaEl = (document.querySelector('[fs-prop="analytics"]') != null) ? getPageMetadata() : null;
    this.segmentApiKey = null;
    this.adCurrentTime = 0;
    this.secondsViewed = 0;
    this.enableLogs = location.href.includes('logs=true');

    // tracking functions
    this.trackPage = this.trackPage.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.trackVideoEvent = this.trackVideoEvent.bind(this);
    this.isTrackingAvail = this.isTrackingAvail.bind(this);
    this.setVideoProps = this.setVideoProps.bind(this);
    this.getUUID = this.getUUID.bind(this);
    this.getAuthData = this.getAuthData.bind(this);
    this.initScript = this.initScript.bind(this);
  }

  /**
	 * get device id
	 */
  getUUID () {
    return getUUID();
  }

  /**
	 * get required auth/profile data
	 */
  getAuthData () {
    return getAuthData();
  }

  getProfileData () {
    return getProfileData();
  }

  getCategory () {
    return this.metaEl.category;
  }

  getSubCategory () {
    return this.metaEl.sub_category;
  }

  getLandingName () {
    let name = this.metaEl ? this.metaEl.landing_name : '';
    if (name) { name = name.replace(/\s\s+/g, ' '); }
    // CHECK: if page has hash string
    if (window.location.hash) {
      const hash = window.location.hash;
      if (hash.includes('nav-option')) {
        name = name.replace('Landing', 'Category');
      }
    }
    return name;
  }

  getPageName () {
    const pageName = this.metaEl ? this.metaEl.seg_page_name : 'no page name';
    if (pageName) { return pageName.replace('::', ':'); }
  }

  getPageContentTitle () {
    return this.metaEl ? this.metaEl.page_content_title : 'no page content title';
  }

  getPageSubType () {
    return this.metaEl ? this.metaEl.page_sub_type : 'no page sub type';
  }

  getContentName () {
    return this.metaEl ? this.metaEl.content_name : 'no content name';
  }

  getStoryTitle () {
    return this.metaEl ? this.metaEl.story_title : 'no story title';
  }

  getBuildVersion () {
    let buildVersion = this.metaEl ? this.metaEl.build_version : '';
    if (buildVersion && document.getElementById('__nuxt')) {
      buildVersion = buildVersion.substr(1, buildVersion.length);
    }
    return buildVersion;
  }

  getUsPrivacy () {
    let usPrivacy = getCookie('us_privacy');
    if (!usPrivacy) {
      usPrivacy = this.metaEl ? this.metaEl.us_privacy : '';
    }
    return usPrivacy;
  }

  getEntityUris () {
    return this.metaEl ? this.metaEl.content_entity_uri : undefined;
  }

  getContentAuthor () {
    return this.metaEl ? this.metaEl.page_content_author : undefined;
  }

  getPageType () {
    const pageType = this.metaEl ? this.metaEl.page_type : 'no page type';
    return pageType;
  }

  getPageContentLevels (props) {
    if (this.metaEl) {
      if (this.metaEl.page_content_level_1) { props.properties.page_content_level_1 = this.metaEl.page_content_level_1.replace('::', ':'); }
      if (this.metaEl.page_content_level_2) { props.properties.page_content_level_2 = this.metaEl.page_content_level_2.replace('::', ':'); }
      if (this.metaEl.page_content_level_3) { props.properties.page_content_level_3 = this.metaEl.page_content_level_3.replace('::', ':'); }
      if (this.metaEl.page_content_level_4) { props.properties.page_content_level_4 = this.metaEl.page_content_level_4.replace('::', ':'); }
    } else {
      props.properties.page_content_level_1 = 'no content level 1';
      props.properties.page_content_level_2 = 'no content level 2';
      props.properties.page_content_level_3 = 'no content level 3';
      props.properties.page_content_level_4 = 'no content level 4';
    }
    return props;
  }

  getPosition (position, adTime = 0) {
    return parseInt(position - adTime, 10);
  }

  getDuration (duration) {
    if (duration) {
      duration = parseInt(duration, 10);
      if (duration > 0 && duration != this.duration) { this.duration = duration; }
    }
    return this.duration;
  }

  setSecondsViewed (secondsViewed) {
    if (secondsViewed && secondsViewed > 0) { this.secondsViewed = secondsViewed; }
  }

  getSecondsViewed (eventName) {
    if (eventName.includes('Playing')) { return 30; }
    return Math.floor(this.secondsViewed % 10);
  }

  setCampaignTraits (props) {
    const campaignTraits = getCampaignTraits();
    if (campaignTraits) {
      for (const trait in campaignTraits) {
        props.properties[trait] = campaignTraits[trait];
      }
    }
    return props;
  }

  getDefaultProps (type, eventName) {
    const traits = {
      dcg_profile_id: '',
      appsflyer_id: '',
      network_entitlement_list: '',
      lastKnownProfileId: '',
      lastAnonymousProfileId: '',
      mvpd: ''
    };
    const defaultProps = {
      primary_business_unit: 'fng',
      secondary_business_unit: 'fox sports',
      app_name: 'foxsports.com',
      app_platform: 'web',
      app_version: '',
      app_build: '',
      page_test_name: '',
      page_timePartingDay: '',
      page_timePartingHour: '',
      campaign_tracking_code: '',
      campaign_organization: '',
      campaign_marketing_channel: '',
      campaign_source: '',
      campaign_name: '',
      campaign_additional: '',
      campaign_agency: '',
      page_name: '',
      page_type: '',
      page_content_level_1: '',
      page_content_level_2: '',
      page_content_level_3: '',
      page_content_level_4: '',
      favorites_length: '',
      favorites_list: '',
      favorites_sports_length: '',
      favorites_sports_list: '',
      favorites_teams_length: '',
      favorites_teams_list: '',
      favorites_personalities_length: '',
      favorites_personalities_list: '',
      rivals_sports_length: '',
      rivals_sports_list: '',
      rivals_teams_length: '',
      rivals_teams_list: '',
      subscriptions_length: '',
      subscriptions_list: '',
      push_enabled: ''
    };

    let props;

    // for page view
    if (type === 'page') {
      props = Object.assign(defaultProps, {
        keywords: '',
        page_content_author: '',
        page_content_original_publish_date: '',
        page_content_major_publish_date: '',
        page_content_modified_date: '',
        page_content_original_publish_time: '',
        page_content_major_publish_time: '',
        page_content_modified_time: '',
        page_content_version: '',
        page_content_title: '',
        page_content_id: '',
        page_content_type: '',
        page_content_type_of_story: '',
        page_content_tags: '',
        page_content_category: '',
        page_content_subcategory: '',
        page_content_language: '',
        page_login_state: '',
        page_content_originator: ''
      });
      return {
        name: '',
        context: { traits },
        properties: props
      };
    }
    props = defaultProps;
    return {
      event: eventName || '',
      context: { traits },
      properties: props
    };
  }

  /**
	 * set all video properties
	 * @param {Object} data - player config
	 * @param {Object} e - context, traits, video props
	 */
  setVideoProps (data, e) {
    const props = {
      video_primary_business_unit: 'fng',
      video_secondary_business_unit: 'fox sports',
      video_sports_type: data.sportTag,
      video_tms_id: data.tmsId,
      ...e.trackingData.video
    };

    return props;
  }

  /**
	 * set main content
	 * @param data - player config
	 * @param e - event data sent by player
	 */
  setMainContentProps (data, e) {
    const content = { ...data.trackingVideoDataMainContent };
    const trackingDataContent = e.trackingData.content || data.trackingVideoData;
    const trackingDataGetCallback = (prop) => {
      const td = e.trackingData.content || {};
      const tvd = data.trackingVideoData || {};
      const tdRes = td[prop];
      const tvdRes = tvd[prop];

      return tdRes !== undefined ? tdRes : tvdRes;
    };

    const trDataProps = [
      'pod_id', 'video_tms_id', 'video_content_length', 'program', 'full_episode', 'airdate', 'total_length', 'load_type'
    ];

    trDataProps.forEach((e) => {
      const tmp = trackingDataGetCallback(e);
      if (tmp === undefined) {
        return;
      }

      content[e] = tmp;
    });

    if (e.trackingData.video) {
      const video = e.trackingData.video;
      if (video.video_asset_id) { content.asset_id = video.video_asset_id; }
      if (video.position || typeof video.position === 'number') { content.position = video.position; }
      if (video.total_length) { content.total_length = video.total_length; }
      if (video.video_asset_title) {
        content.title = video.video_asset_title;
        if (video.video_series_name &&
                    !video.video_series_name.includes(content.title) &&
                    !content.title.includes(video.video_series_name)) {
          content.title = `${video.video_series_name} - ${content.title}`;
        }
      }
    }

    if (trackingDataContent) {
      if (trackingDataContent.pod_id) { content.pod_id = trackingDataContent.pod_id; }
      if (trackingDataContent.title) {
        content.title = trackingDataContent.title;

        if (trackingDataContent.program &&
                    !trackingDataContent.program.includes(content.title) &&
                    !content.title.includes(trackingDataContent.program)) {
          content.title = `${trackingDataContent.program} - ${content.title}`;
        }
      }
    }

    return content;
  }

  /**
	 * get video props
	 * @param {Object} data - player data
	 * @param {Object} e - event which contains tracking data
	 */
  getVideoProps (data, e) {
    // get playback data
    let props = data.trackingVideoData;
    let subTypeProps = {};
    const trackingData = e.trackingData;
    if (trackingData) {
      if (trackingData.ad) {
        subTypeProps = { ...subTypeProps, ...trackingData.ad };
      }
      if (trackingData.content) {
        subTypeProps = { ...subTypeProps, ...trackingData.content };
      }
      if (trackingData.playback) {
        subTypeProps = { ...subTypeProps, ...trackingData.playback };
        subTypeProps.content_asset_ids = trackingData.content_asset_ids;
        subTypeProps.content_pod_ids = trackingData.content_pod_ids;
      }
      if (trackingData.video) {
        subTypeProps = { ...subTypeProps, ...trackingData.video };
      }
    }
    if (e.type === 'onPlaybackInterrupted') {
      subTypeProps.method = e.method;
    }
    if (e.type.includes('Content')) {
      subTypeProps.full_episode = data.data && (data.data.videoType === 'fullEpisode').toString();
    }
    if (['Playback', 'Content', 'Ad', 'Buffer', 'Seek']
      .some(n => e.type.includes(n))) {
      subTypeProps.video_is_continuous = data.isContinuous;
    }

    if (data.data.categoryTags) {
      subTypeProps.video_content_tags = data.data.categoryTags;
    }

    props = { ...props, ...subTypeProps };

    if (data.data.embed) {
      props = { ...props, ...data.data.embedAnalyticsProps }
    }
    // get content data
    return props;
  }

  getSegmentEventName (eventName) {
    const eventsEnum = {
      OnPlaybackStart: 'Video Playback Started',
      OnPlaybackComplete: 'Video Playback Completed',
      OnPlaybackInterrupted: 'Video Playback Interrupted',
      OnPlaybackPaused: 'Video Playback Paused',
      OnPlaybackResumed: 'Video Playback Resumed',
      OnSeekStart: 'Video Playback Seek Started',
      OnSeekComplete: 'Video Playback Seek Completed',
      OnBufferStart: 'Video Playback Buffer Started',
      OnBufferEnd: 'Video Playback Buffer Completed',
      OnBuffering: 'Video Playback Buffering',
      OnContentStart: 'Video Content Started',
      OnContentComplete: 'Video Content Completed',
      OnContentPlaying: 'Video Content Playing',
      OnAdStart: 'Video Ad Started',
      OnAdPlaying: 'Video Ad Playing',
      OnAdComplete: 'Video Ad Completed',
      OnAdBufferStart: 'Video Ad Buffer Started',
      OnAdBufferEnd: 'Video Ad Buffer Completed',
      OnAdPodStart: 'Video Ad Break Started',
      OnAdPodComplete: 'Video Ad Break Completed',
      OnAdPause: 'Video Ad Paused',
      OnAdResume: 'Video Ad Resumed',
      OnSlateComplete: 'Video Slate Complete',
      OnSlateStart: 'Video Slate Start',
      OnSlatePlaying: 'Video Slate Playing'
    };
    return (eventsEnum[eventName]) ? eventsEnum[eventName] : null;
  }

  /**
	 * sets global trais and custom properties
	 * @param {*} props - segment global props
	 * @param {*} data - player config
	 */
  setGlobalProps (props) {
    const pageName = this.getPageName();
    const pageContentTitle = this.getPageContentTitle();
    const pageType = this.getPageType();
    const storyTitle = this.getStoryTitle();
    const buildVersion = this.getBuildVersion();
    const usPrivacy = this.getUsPrivacy();
    const entityUris = this.getEntityUris();
    const contentAuthor = this.getContentAuthor();

    if (pageName) { props.properties.page_name = pageName; }
    if (pageContentTitle) { props.properties.page_content_title = pageContentTitle; }
    if (pageType) { props.properties.page_type = pageType; }
    if (storyTitle) {
      props.properties.story_title = storyTitle;
      if (props.properties.title) { props.properties.title = storyTitle; }
      if (props.properties.program) { props.properties.program = storyTitle; }
    }
    if (buildVersion) {
      props.properties.app_build = buildVersion;
      props.properties.app_version = buildVersion;
    }
    if (usPrivacy) {
      props.properties.us_privacy = usPrivacy;
    }

    if (entityUris) {
      props.properties.content_entity_uri = entityUris;
    }

    if (contentAuthor) {
      props.properties.page_content_author = contentAuthor;
    }

    props.context.traits = getProfileData(props.context.traits);
    if (this.auth) {
      props.context.traits.mvpd = this.auth.mvpdId || '';
      props.context.traits.network_entitlement_list = this.auth.networkEntitlementList || '';
    }

    const xid = getXID();
    if (xid) {
      props.context.traits.user_xid = xid;
    }
    props = getFavoritesLists(props);

    props = this.getPageContentLevels(props);
    props = this.setCampaignTraits(props);

    this.updatePropsContextTraits(props);

    return props;
  }

  /**
	 * removes all empty/null custom properties
	 * @param {Object} props - analytics props object
	 * @return {Object} - returns object only if not empty/null
	 */
  updateAllProps (props) {
    const newProperties = {};
    for (const prop in props.properties) {
      if (props.properties[prop] || typeof props.properties[prop] === 'boolean' || typeof props.properties[prop] === 'number') {
        newProperties[prop] = props.properties[prop];
      }
    }
    props.properties = newProperties;
    return props;
  }

  getPageProps (name, data) {
    let props = this.setGlobalProps(this.getDefaultProps('page'));
    props.name = name || this.getLandingName();
    const isCustomProps = !!data.properties;
    if (data) {
      if (isCustomProps) {
        for (const metaKey in data.properties) {
          props.properties[metaKey] = data.properties[metaKey]
        }
      } else {
        for (const key in props.properties) {
          for (const metaKey in data) {
            if (key == metaKey && metaKey !== 'page_name') {
              props.properties[key] = data[metaKey]
            }
          }
        }
      }
      if (data.context && data.context.traits) {
        for (const trait in data.context.traits) {
          props.context.traits[trait] = data.context.traits[trait];
        }
      }
    }
    props = this.updateAllProps(props);
    return props;
  }

  getEventProps (eventName, data) {
    eventName = this.getSegmentEventName(eventName);
    let props = this.setGlobalProps(this.getDefaultProps('event', eventName));
    props.event = eventName;
    if (data) {
      if (data.properties) {
        for (const prop in data.properties) {
          props.properties[prop] = data.properties[prop];
        }
      }
      if (data.context && data.context.traits) {
        for (const trait in data.context.traits) {
          props.context.traits[trait] = data.context.traits[trait];
        }
      }
    }
    props = this.updateAllProps(props);
    return props;
  }

  updatePropsContextTraits (props) {
    const cmpParameters = getCurrentCmpParameters();

    const cmpId = cmpParameters.cmpid;
    const isInternal = cmpParameters.internal;

    props.context.traits[`campaign_${isInternal ? 'int_' : ''}tracking_code`] = cmpId;
  }

  /**
	 * get all video event props
	 * @param {string} eventName
	 * @param {Object} data
	 * @param {Object} e
	 */
  getVideoEventProps (eventName, data, e) {
    let props = this.setGlobalProps(this.getDefaultProps('video', eventName));
    props.properties = { ...props.properties, ...this.getVideoProps(data, e) };

    switch (eventName) {
      case 'Video Ad Playing':
        this.adCurrentTime = e.currentTime;
        props.properties.video_seconds_viewed = 5;
        break;
      case 'Video Content Playing':
        props.properties.video_seconds_viewed = this.getSecondsViewed(eventName);
        break;
      case 'Video Playback Seek Started':
        props.properties.seek_position = Math.round(e.start);
        break;
      case 'Video Playback Seek Completed':
        props.properties.position = Math.round(e.start);
        break;
      default: break;
    }

    if ([
      'Video Ad Started',
      'Video Ad Playing',
      'Video Ad Completed'
    ].includes(eventName)) {
      props.properties.content = data.trackingVideoDataMainContent;
    }

    if ([
      'Video Ad Started',
    ].includes(eventName)) {
      props.properties.asset_id = e.trackingData.ad.asset_id;
    }

    props = this.updateAllProps(props);
    return props;
  }

  check () {
    return !window.analytics;
  }

  /**
	 * TODO: review this method with Andrei
	 * @param {*} eventName
	 * @param {*} data
	 */
  isCanTrackVideo (eventName, e) {
    const currentTime = e.currentTime;
    if (eventName === 'Video Ad Completed') {
      return e.type === 'onAdComplete';
    }
    if (eventName === 'Video Ad Playing') {
      if (!currentTime) { return false; }
		    return Math.floor(this.adCurrentTime) !== Math.floor(currentTime);
    }
    return true;
  }

  /**
	 * Checks if we can use segment analytics script
	 * @param {stirng} eventName
	 * @param {Object} data
	 * @param {boolean} isVideo
	 */
  isTrackingAvail (eventName, data, isVideo = false) {
    const self = this;
    /* https://segment.com/docs/sources/website/analytics.js/#track */
    if (self.check()) {
      self.initScript(self.segmentApiKey, false);
      if (!window.analytics) {
        throw ('SEGMENT ANALYTICS !! - Segment Analytics is not initialized');
      }
    }
    // handle script checks for video
    if (isVideo) {
      if (!eventName) { throw ('SEGMENT ANALYTICS !! - video event name is required'); }
      if (!data) { throw ('SEGMENT ANALYTICS !! - video event data is required'); }
      // check if event is supported
      const currentEventName = self.getSegmentEventName(eventName);
      if (!currentEventName) {
        throw (`SEGMENT ANALYTICS !! - event(${eventName}) is not recognized as a segment event.`);
      }
      self.currentEventName = currentEventName;
      return self;
    } else {
      // check for meta el for page tracking
      const meta = data || self.metaEl;
      if (!meta) {
        throw ('SEGMENT ANALYTICS !! - analytics meta tag or props are required and are missing');
      }
      return self;
    }
  }

  isDeprecated (currentPageName) {
    const deprecationUrls = [
      'Sign Up Account', 'Sign In', 'Sign Up Payment', 'Articles Amp Page'
    ];
    const deprecatedPagesNames = [
      ...deprecationUrls, ...(
        window.fsSettings && window.fsSettings.SADeprecatedTitles
          ? window.fsSettings.SADeprecatedTitles : []
      )
    ];

    if (deprecatedPagesNames.map(v => v.toLowerCase().trim())
      .includes(currentPageName.toLowerCase().trim())) {
      return true;
    }

    return false;
  }

  /**
	 * track page events
	 * @param {string} name
	 * @param {Object} props
	 */
  trackPage (name, props) {
    return new Promise((resolve, reject) => {
      if (this.isDeprecated(name || this.getLandingName())) {
        this.log(`SegmentAnalytics: tracking deprecated (${name})`);

        return resolve();
      }

      try {
        const self = this.isTrackingAvail(name, props);
        if (self) {
          const meta = props || self.metaEl;
          props = self.getPageProps(name, meta);
          name = name || props.name;
					if (props.page_type === 'explore' || (props.properties && props.properties.page_type === 'explore')) {
						delete props.properties.content_entity_uri;
					}
          /* NOTE: `trackPage` params takes priority over default/meta values from getPageProps */
          this.log(`Segment page tracking (${name}): ${JSON.stringify(props)}`);
          window.analytics.page(name, props.properties, { context: props.context });
          return resolve();
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
	 * track video events
	 * @param {string} eventName
	 * @param {Object} data
	 * @param {Objec} e
	 */
  trackVideoEvent (eventName, data, e) {
    return new Promise((resolve, reject) => {
      try {
        const self = this.isTrackingAvail(eventName, data, true);
        if (self) {
          eventName = self.currentEventName;
          if (!self.isCanTrackVideo(eventName, e)) { return resolve(); }
          self.auth = data.auth;
          const props = self.getVideoEventProps(eventName, data, e);
          this.log(`Segment video event tracking (${eventName}) - ${JSON.stringify(props)}`);
          window.analytics.track(eventName, props.properties, { context: props.context });
          return resolve();
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
	 * track action events
	 * @param {*} eventName
	 * @param {*} data
	 */
  trackEvent (eventName, data) {
    return new Promise((resolve, reject) => {
      try {
        const self = this.isTrackingAvail(eventName, data);
        if (self) {
          const props = self.getEventProps(eventName, data);
          this.log(`Segment event tracking (${eventName}) - ${JSON.stringify(props)}`);
          window.analytics.track(eventName, props.properties, { context: props.context });
          return resolve();
        }
        return reject();
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
	 * init segment analytics script
	 * @param {string} segmentApiKey
	 * @param {boolean} enablePageLoadTracking
	 */
  initScript (segmentApiKey = null, enablePageLoadTracking = true) {
    const self = this;

    if (self.check()) {
      self.segmentApiKey = (function (key) {
        if (typeof key === 'string' && key.length > 0) { return key; }
        return (self.metaEl != null && (self.metaEl.hasOwnProperty('segment_api_key') && self.metaEl.segment_api_key)) ? self.metaEl.segment_api_key : null;
      })(segmentApiKey);

      !(async function () {
        const analytics = window.analytics = window.analytics || []; if (!analytics.initialize) {
          if (analytics.invoked) { window.console && console.error && console.error('Segment snippet included twice.'); } else {
            analytics.invoked = !0; analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on']; analytics.factory = function (t) { return function () { const e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics; }; }; for (let t = 0; t < analytics.methods.length; t++) { const e = analytics.methods[t]; analytics[e] = analytics.factory(e); }analytics.load = function (t) { const e = document.createElement('script'); e.type = 'text/javascript'; e.async = !0; e.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'; const n = document.getElementsByTagName('script')[0]; n.parentNode.insertBefore(e, n); }; analytics.SNIPPET_VERSION = '4.0.0';
            window.analytics.load(self.segmentApiKey);
            // get profile auth information
            const auth = await getAuthData();
            self.auth = auth;
            if (enablePageLoadTracking && !self.metaEl.hasOwnProperty('disable_page_tracking')) {
              self.trackPage();
            }
            window.analytics.ready(() => {
              const profile = {
                dcg_profile_id: auth.profile.profileId,
                lastAnonymousProfileId: auth.profile.lastAnonymousProfileId,
                lastKnownProfileId: auth.profile.lastKnownProfileId
              };
              window.dataLayer && dataLayer.push(profile);
              if (window.s) {
                window.s.trackExternalLinks = true;
                window.s.linkInternalFilters = 'fox.com,foxsports.com,nation.foxnews.com,foxnewsinternational.com,fox29.com,fox5ny.com,foxla.com,fox32chicago.com,' +
                  'fox4news.com,ktvu.com,fox5dc.com,fox5atlanta.com,fox26houston.com,fox13news.com,fox10phoenix.com,fox2detroit.com,fox9.com,' +
                  'fox35orlando.com,fox7austin.com,wogx.com,my9nj.com,coronavirusnow.com,q13fox.com,fox6now.com';
              }
            });
          }
        }
      }());
    }
  }

  log () {
    this.enableLogs && console.log.apply(console, arguments);
  }
}

window.SegmentAnalytics = new SegmentAnalytics();

export default window.SegmentAnalytics;
